import { ReactElement, useEffect, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { useSelector } from "react-redux";

import strings from "@locale/localization";
import GridContainer from "../../components/GridContainer";
import { useHistory, useLocation } from "react-router";
import useAuth from "../../auth/useAuth";

import { CSSstylesObject, PodmeColor } from "../../types";

import { RootState } from "../../reducers/rootReducer";
import { useDisableScroll } from "../../hooks";

import PremiumPromoView from "./components/PremiumPromoView";
import { IdentityProvider, IdpRedirectOption } from "@auth/authTypes";

interface Props {
    isOpen: boolean;
    isCloseable?: boolean;
    onClose: () => void;
}
const styles: CSSstylesObject = {
    closeIcon: {
        position: "absolute",
        right: "1rem",
        top: "1rem",
        width: "22px",
        height: "22px",
        display: "inline",
        cursor: "pointer",
        zIndex: 10,
        sx: {
            "&:hover": {
                opacity: 1,
            },
            "&:before,&:after": {
                position: "absolute",
                right: "calc(50% - 1px)",
                content: '" "',
                height: "22px",
                width: "2px",
                backgroundColor: PodmeColor.White,
                marginTop: "calc(-22px / 2)",
            },
            "&:before": {
                transform: "rotate(45deg)",
            },
            "&:after": {
                transform: "rotate(-45deg)",
            },
        },
    },
};

const globalState = (state: RootState) => state.global;
const contentState = (state: RootState) => state.content.content;

export default function OnboardingModal({ isOpen, isCloseable = true, onClose }: Props): ReactElement | null {
    const location = useLocation();
    const history = useHistory();
    const { isAuthenticated, hasActiveSubscription, subscriptionStatusChecked, episodeClicked, loginWithIdp } = useAuth();
    const { isEligibleForTrial } = useSelector(globalState);
    const content = useSelector(contentState);
    const [offerAccepted, setOfferAccepted] = useState(false);
    const [alreadyHaveAccount, setAlreadyHaveAccount] = useState(false);
    const { locale, signup } = strings.routes;

    const goBackUrl = typeof window !== "undefined" ? window.location.pathname : "";

    useDisableScroll(isOpen);

    useEffect(() => {
        if (isAuthenticated && subscriptionStatusChecked) {
            if (hasActiveSubscription) {
                onClose();
            } else if ((isOpen && offerAccepted) || (isOpen && alreadyHaveAccount)) {
                onClose();
                history.push(`/${locale}/${signup}/checkout`, { goBackUrl });
            }
        } else if (isOpen && offerAccepted) {
            onClose();
            loginWithIdp(IdentityProvider.Schibsted, IdpRedirectOption.SignupPage, location.pathname);
        }
    }, [isOpen, offerAccepted, hasActiveSubscription, isAuthenticated, subscriptionStatusChecked]);

    useEffect(() => {
        if (!isOpen) {
            setOfferAccepted(false);
            setAlreadyHaveAccount(false);
        }
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <Flex position="fixed" top={0} left={0} right={0} bottom={0} zIndex={9999}>
            <Box
                background={PodmeColor.Cinder}
                position="relative"
                zIndex={2000}
                rounded={["0", "1.5rem"]}
                width="100%"
                maxW="42rem"
                padding={[
                    "3rem 1rem",
                    isAuthenticated ? "4rem 2rem" : "4rem 2rem 2rem",
                    isAuthenticated ? "4rem 3rem 3rem" : "4rem 3rem 2rem",
                ]}
                minHeight={["100%", "unset"]}
                margin={["0", "auto"]}
            >
                {isCloseable && (
                    <Box
                        as={"button"}
                        {...styles.closeIcon}
                        onClick={() => {
                            setOfferAccepted(false);
                            setAlreadyHaveAccount(false);
                            onClose();
                        }}
                    />
                )}
                <GridContainer columns={6} position="relative" zIndex={2000}>
                    <PremiumPromoView
                        ctaOnClick={() => setOfferAccepted(true)}
                        isAuthenticated={isAuthenticated}
                        showTrialCopy={isEligibleForTrial}
                        perks={content?.premiumBenefits}
                        episodeClicked={episodeClicked}
                    />
                </GridContainer>
            </Box>
            {isOpen && (
                <Box as="span" position="fixed" left={0} top={0} right={0} bottom={0} zIndex={1} background="black" opacity={0.7} />
            )}
        </Flex>
    );
}
